import {Component, OnInit} from '@angular/core';
import {AuthService} from "./services/auth/auth.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    username: string = ''

    isLoggedIn: boolean = false

    constructor(private _authService: AuthService) {

    }

    ngOnInit(): void {
        this._authService.loggedIn()
            .subscribe(isLoggedIn => {
                this.isLoggedIn = isLoggedIn
                if (isLoggedIn) {
                    this.username = this._authService.getUsername()
                }
            })
    }


}
