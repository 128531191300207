import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { UnitService } from "../services/unit.service";
import { UnitCreateDto } from "../model/dto/unit-create.dto";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-create-unit",
  templateUrl: "./create-unit.component.html",
  styleUrls: ["./create-unit.component.css"],
})
export class CreateUnitComponent implements OnInit {
  private shipment: string;
  unitForm: FormGroup = new FormGroup({
    id: new FormControl("", [Validators.required]),
  });

  constructor(
    private _unitService: UnitService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.shipment = params.get("shipment");
    });
  }

  submit = () =>
    this._unitService
      .createUnit(<UnitCreateDto>{
        id: this.unitForm.get("id").value,
        shipment: this.shipment,
      })
      .subscribe(() => {
        this.router.navigate([`/shipment/${this.shipment}`]);
      });
}
