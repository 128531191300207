import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UnitService } from "../services/unit.service";
import { UserActionDetailResponse } from "../model/response/user-action-detail.response";
import { GpsLocation } from "../model/dto/unit-event.dto";

var formatcoords = require("formatcoords");

@Component({
  selector: "app-user-detail-overlay",
  templateUrl: "./user-detail-overlay.component.html",
  styleUrls: ["./user-detail-overlay.component.css"],
})
export class UserDetailOverlayComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { userId: string; unitId: string },
    private readonly _unitService: UnitService
  ) {}

  userActionDetailResponse: UserActionDetailResponse | null;

  ngOnInit(): void {
    this._unitService
      .getUserDetailsWithActionEvents(this.data.userId, this.data.unitId)
      .subscribe((value) => {
        this.userActionDetailResponse = value;
      });
  }

  locationToString(gpsLocation: GpsLocation) {
    return formatcoords(gpsLocation.latitude, gpsLocation.longitude).format({
      latLonSeparator: ", ",
      decimalPlaces: 3,
    });
  }
}
