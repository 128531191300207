import {Component, HostListener, OnInit, ViewChild} from "@angular/core";
import { AuthService } from "../services/auth/auth.service";
import { Router } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { DialogLoginComponent } from "./dialog-login/dialog-login";
import {MatDrawer} from "@angular/material/sidenav";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  shipmentSearch = new FormGroup({
    id: new FormControl("", [Validators.required]),
  });

  username: string = "";

  isLoggedIn: boolean = false;

  constructor(
    private _authService: AuthService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  @ViewChild('drawer') drawer: MatDrawer;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth > 1400) {
      this.drawer.close()
    }
  }

  ngOnInit(): void {
    this._authService.loggedIn().subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
      if (isLoggedIn) this.username = this._authService.getUsername();
      else this.username = "";
    });
  }

  logOut = () => {
    this._authService.logout();
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigate(["/home"]).then((_) => {
        document.location.reload();
      });
    });
  };

  searchShipment() {
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigate([`/shipment/${this.shipmentSearch.value.id}`]);
    });
  }

  openLoginDialog(): void {
    this.dialog.open(DialogLoginComponent, {
      backdropClass: "backdropBackgroundBlur",
      panelClass: "dialog-transparent-background",
    });
  }

  navigateToNewShipment() {
    this.router.navigate([`/shipment-create`]);
  }

  navigateToHome() {
    this.router.navigate([`/home`]);
  }

  navigateToApi() {
    this.router.navigate([`/shipment-api`]);
  }
}
