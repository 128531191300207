<div class="dialog-container">
    <div style="width: 550px">
        <div style="background-color: lightgray; padding: 10px">
            <p>Name:</p>
            <p><b>{{userActionDetailResponse.name}}</b></p>
        </div>
        <br>
        <div style="float: left; width: 100%; padding-left: 10px">

            <div class="row">
                <p class="grow">Role: </p>
                <p class="fill"><b>{{userActionDetailResponse.role}}</b></p>
            </div>
            <div class="row">
                <p class="grow">Organization: </p>
                <p class="fill"><b>{{userActionDetailResponse.organization}}</b></p>
            </div>
            <div class="row">
                <p class="grow">Phone No.: </p>
                <p class="fill"><b>{{userActionDetailResponse.phoneNumber}}</b></p>
            </div>

        </div>
    </div>
    <p style="padding-left: 10px"><b>Messages</b></p>
    <mat-divider></mat-divider>
    <div *ngFor="let event of userActionDetailResponse.actionEvents">
        <div [style.background]="event.unitUpdateSequenceNumber ? 'lightGrey' : 'transparent'" style="padding: 10px">
            <span *ngIf="event.unitUpdateSequenceNumber"><b>{{event.unitUpdateSequenceNumber}}
                / {{userActionDetailResponse.totalUnitUpdates}} for this unit </b></span>
            <span *ngIf="event.unitUpdateSequenceNumber == null"><b>General update </b></span>
            <span>{{event.timestamp}}</span>
            <div style="display: flex; align-items: center;">
                <mat-icon style="margin-right: 5px;">map</mat-icon>
                <span>{{locationToString(event.location)}}</span>
            </div>
            <div style="display: flex; align-items: center;">
                <mat-icon style="margin-right: 5px;">comment</mat-icon>
                <span>{{event.comment ?? 'N/A'}}</span>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button class="btn btn-primary" [mat-dialog-close]='true' style="margin-left: auto" tabindex='-1'>CLOSE</button>
</div>