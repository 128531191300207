import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RegisterComponent} from "./register/register.component";
import {HomeComponent} from "./home/home.component";
import {CreateShipmentComponent} from "./create-shipment/create-shipment.component";
import {TrackShipmentComponent} from "./track-shipment/track-shipment.component";
import {TrackUnitComponent} from "./track-unit/track-unit.component";
import {IsAuthenticatedGuard} from "./services/auth/is-authenticated.guard";
import {CreateUnitComponent} from "./create-unit/create-unit.component";
import {ApiComponent} from "./api/api.component";
import {MeshNodeStatusEventComponent} from "./mesh-node-status-event/mesh-node-status-event.component";
import {KpiComponent} from "./kpi/kpi.component";
import {KpiShipmentComponent} from "./kpi-shipment/kpi-shipment.component";

const routes: Routes = [
    {path: '', redirectTo: 'home', pathMatch: 'prefix'},
    {path: 'home', component: HomeComponent},
    {path: 'register', component: RegisterComponent, canActivate: [IsAuthenticatedGuard]},
    {path: 'shipment-create', component: CreateShipmentComponent, canActivate: [IsAuthenticatedGuard]},
    {path: 'unit-create/:shipment', component: CreateUnitComponent, canActivate: [IsAuthenticatedGuard]},
    {path: 'shipment/:id', component: TrackShipmentComponent, canActivate: [IsAuthenticatedGuard]},
    {path: 'unit-event', component: TrackUnitComponent, canActivate: [IsAuthenticatedGuard]},
    {path: 'shipment-api', component: ApiComponent, canActivate: [IsAuthenticatedGuard]},
    {path: 'mesh-node-status', component: MeshNodeStatusEventComponent, canActivate: [IsAuthenticatedGuard]},
    {path: 'kpi', component: KpiComponent, canActivate: [IsAuthenticatedGuard]},
    {path: 'kpi-shipment/:shipmentId', component: KpiShipmentComponent, canActivate: [IsAuthenticatedGuard]},
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
