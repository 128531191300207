import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {FetchEventTransferDelayResponse} from "../model/response/fetch-event-transfer-delay.response";
import {MeshNodeOnlinePercentage} from "../model/response/mesh-nodes-online-percentage.response";
import {VerifiedEventPercentageResponse} from "../model/response/verified-event-percentage.response";
import {KpiKeyConstants} from "../model/constants/kpi.constants";
import {KpiResponse} from "../model/response/kpi.response";
import {TotalActionEventsForShipmentResponse} from "../model/response/total-action-events-for-shipment.response";
import {KpiShipmentResponse} from "../model/response/kpi-shipment.response";

@Injectable({
    providedIn: "root",
})
export class KpiService {
    baseUrl = environment.apiUrl;

    constructor(private _httpClient: HttpClient) {
    }

    fetchEventTransferDelay = (shipmentIds: string[]): Observable<FetchEventTransferDelayResponse[]> =>
        this._httpClient.get<FetchEventTransferDelayResponse[]>(`${this.baseUrl}/kpi/event-transfer-delay`, {
            params: new HttpParams({
                fromObject: {
                    'shipmentIds[]': [...shipmentIds]
                }
            })
        });

    getMeshNodeOnlinePercentage = (shipmentIds: string[]): Observable<VerifiedEventPercentageResponse[]> =>
        this._httpClient.get<VerifiedEventPercentageResponse[]>(`${this.baseUrl}/kpi/online`, {
            params: new HttpParams({
                fromObject: {
                    'shipmentIds[]': [...shipmentIds]
                }
            })
        });

    getVerifiedEventPercentage = (shipmentIds: string[]): Observable<VerifiedEventPercentageResponse[]> =>
        this._httpClient.get<VerifiedEventPercentageResponse[]>(`${this.baseUrl}/kpi/verified-event-percentage`, {
            params: new HttpParams({
                fromObject: {
                    'shipmentIds[]': [...shipmentIds]
                }
            })
        });

    getActionEventsPerShipment = (shipmentIds: string[]): Observable<TotalActionEventsForShipmentResponse[]> => {
        return this._httpClient.get<TotalActionEventsForShipmentResponse[]>(`${this.baseUrl}/units/events-for-shipment`, {
            params: new HttpParams({
                fromObject: {
                    'shipmentIds[]': [...shipmentIds]
                }
            })
        })
    }

    getKpiShipmentById(shipmentId: string): Observable<KpiShipmentResponse> {
        return this._httpClient.get<KpiShipmentResponse>(`${this.baseUrl}/kpi/kpi-shipment/${shipmentId}`)
    }

    getSuccessfulNumberOfLogins = (): Observable<number> =>
        this._httpClient.get<number>(`${this.baseUrl}/auth/successful-logins`);

    getFailedNumberOfLogins = (): Observable<number> =>
        this._httpClient.get<number>(`${this.baseUrl}/auth/failed-logins`);

    getNumberOfPrintedQrCodes = (): Observable<KpiResponse> =>
        this._httpClient.get<KpiResponse>(`${this.baseUrl}/kpi/${KpiKeyConstants.NUMBER_OF_QR_CODES_DEPLOYED}`);
}
