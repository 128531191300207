import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {AuthService} from "../services/auth/auth.service";
import {RegisterDto} from "../model/dto/register.dto";

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

    registerForm = new FormGroup({
        username: new FormControl(''),
        password: new FormControl('')
    })

    constructor(private _authService: AuthService) {
    }

    ngOnInit(): void {
    }

    submit = () => {
        this._authService.register(<RegisterDto>{
            ...this.registerForm.value,
            role: 'ADMIN'
        }).subscribe()
    }

}
