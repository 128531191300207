<style>
    .qrcode img {
        display: table-cell;
        vertical-align: middle;
    }
</style>


<h1 mat-dialog-title>Print QR Code</h1>
<div class="custom-dialog-container" id='print-section' mat-dialog-content>
    <p>Unit ID: {{data}}</p>
    <qrcode elementType='url' [qrdata]=qrCodeData
            [width]='450'></qrcode>
</div>
<div mat-dialog-actions>
    <button mat-button printSectionId='print-section' ngxPrint>Print</button>
</div>