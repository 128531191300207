<div class='shipment-box'>
  <div style='width: 45%; float:left; margin: 15px'>
    <h3 style='color: black;' (click)='navigateToShipment(shipmentEventDto.shipment.id)'>{{shipmentEventDto.shipment.name}}</h3>
    <p style='color: black' *ngIf="nDays">{{shipmentEventDto.unitEvents.length}} updates in the last {{nDays}} days</p>
    <p style='color: black' *ngIf="nDays == null">{{shipmentEventDto.unitEvents.length}} total updates</p>
    <ul>
      <li *ngIf="shipmentEventDto.unitEvents.length != 0">Last update: {{shipmentEventDto.unitEvents[shipmentEventDto.unitEvents.length - 1].timestamp}}</li>
      <li *ngIf="shipmentEventDto.unitEvents.length != 0">Last action: {{shipmentEventDto.unitEvents[shipmentEventDto.unitEvents.length - 1]?.action ?? 'N/A'}}</li>
            <li *ngIf="shipmentEventDto.unitEvents.length != 0">Last
              message: {{castDatasetToUnitDataset(shipmentEventDto.unitEvents[shipmentEventDto.unitEvents.length - 1].dataset?.value)?.message ?? 'N/A'}}</li>
    </ul>
  </div>
  <div *ngIf="shipmentEventDto.unitEvents.length != 0" class="map-wrapper">
    <div class='map-container'>
      <div class='map-frame'>
        <div id="map{{index}}" class="map"></div>
      </div>
    </div>
  </div>

  <div *ngIf="shipmentEventDto.unitEvents.length == 0" class="map-wrapper">
    <p style="text-align: center">No location data available</p>
  </div>

</div>