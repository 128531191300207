<h1 mat-dialog-title style='text-align:center'>
    <img src='assets/images/logo.svg' alt='logo'>
</h1>
<div mat-dialog-content style="text-align: center">
    <div class='loginContent'>
        <form style='text-align:center; ' [formGroup]='loginForm'
              (submit)='submit()'>
            <h4 style='color: white'>Log in</h4>

            <input required matInput id='user-name' type='text' formControlName='username' placeholder="Username">
            <br>
            <input required matInput id='password' type='password' formControlName='password' placeholder="Password">

            <br>
            <button class="submit-btn" mat-raised-button type='submit' [class.light-grey-background]="!loginForm.valid"
                    [disabled]='!loginForm.valid'>Submit
            </button>

        </form>
    </div>
</div>
<div mat-dialog-actions>
</div>