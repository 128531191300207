<div class="content">
  <h1>{{shipmentName}}</h1>
  <div style="justify-content: space-between; margin-bottom: 20px">
    <div style="float: left; width: 47%; height: 350px">

      <div class="row">
        <p class="grow">Shipment ID:</p>
        <p class="fill">{{shipmentId}}</p>
      </div>
      <div class="row">
        <p class="grow">Unit ID: </p>
        <p class="fill">{{unit.id}}</p>
      </div>
      <div class="row">
        <p class="grow">Unit Type: </p>
        <p class="fill">{{unit.unitType}}</p>
      </div>

    </div>
    <div class="map-container" style="float: right; padding-bottom: 50px; width: 45%">
      <div class="map-frame">
        <div id="map"></div>
      </div>
      <p id="explore-map" (click)="enlargeMap()" style="float: right"><u>Explore on larger map</u></p>

      <button class="btn" (click)="showCode()" style="margin-top: 10px">
        <div style="display: flex; align-items: center">
          <img src="assets/images/printer.png" alt="Print Code" />
          <span>SHOW CODE</span>
        </div>
      </button>
    </div>
  </div>
  <table mat-table [dataSource]="eventsPagination.items" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <ng-container matColumnDef="timeCreated">
      <th mat-header-cell *matHeaderCellDef>Time Created</th>
      <td mat-cell *matCellDef="let element"> {{element.timestamp}} </td>
    </ng-container>

    <ng-container matColumnDef="latitude">
      <th mat-header-cell *matHeaderCellDef>Latitude</th>
      <td mat-cell *matCellDef="let element"> {{element.location.latitude}} </td>
    </ng-container>

    <ng-container matColumnDef="longitude">
      <th mat-header-cell *matHeaderCellDef>Longitude</th>
      <td mat-cell *matCellDef="let element"> {{element.location.longitude}} </td>
    </ng-container>

    <ng-container matColumnDef="meshId">
      <th mat-header-cell *matHeaderCellDef>Mesh Node</th>
      <td mat-cell *matCellDef="let element"> {{element.meshId ?? 'N/A'}} </td>
    </ng-container>

    <ng-container matColumnDef="userId">
      <th mat-header-cell *matHeaderCellDef>Username/ID</th>
      <td mat-cell *matCellDef="let element" (click)="openUserDetailOverlay(element.dataset.userId, unit.id)"> {{element?.dataset?.userId ?? "N/A"}} </td>
    </ng-container>


    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let element"> {{element.action ?? 'N/A'}} </td>
    </ng-container>

    <ng-container matColumnDef="message">
      <th mat-header-cell *matHeaderCellDef>Message</th>
      <td mat-cell *matCellDef="let element"> {{element.dataset?.value?.message ?? 'N/A'}} </td>
    </ng-container>

    <ng-container matColumnDef="validity">
      <th mat-header-cell *matHeaderCellDef>Validity</th>
      <td mat-cell *matCellDef="let element" >
        <img *ngIf="element.signature" class="center" ngSrc="assets/images/green-checkmark.svg" alt="green checkmark"
             height="15" width="15">
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <app-pager
    [totalItems]="eventsPagination?.meta?.totalItems"
    (newItemEvent)="getEventPagination($event)">
  </app-pager>
</div>