import { Component, OnInit } from "@angular/core";
import { ShipmentService } from "../services/shipment.service";
import { AuthService } from "../services/auth/auth.service";
import { ShipmentDto } from "../model/dto/shipment.dto";
import { NavigationExtras, Router } from "@angular/router";
import { UnitDto } from "../model/dto/unit.dto";
import { environment } from "../../environments/environment";

@Component({
  selector: "app-api",
  templateUrl: "./api.component.html",
  styleUrls: ["./api.component.css"],
})
export class ApiComponent implements OnInit {
  shipments: ShipmentDto[];

  textArea: string | null = null;

  constructor(
    private shipmentService: ShipmentService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.shipmentService
      .getAllShipmentByUsername(this.authService.getUsername())
      .subscribe((it) => {
        this.shipments = it;
        this.prettyPrint(it);
      });
  }

  prettyPrint(shipments: ShipmentDto[]) {
    this.textArea = JSON.stringify(shipments, undefined, 4);
  }

  navigateToShipment = (shipmentId: string) =>
    this.router.navigate([`shipment/${shipmentId}`]);

  navigateToUnit(unit: UnitDto, shipmentId: string, shipmentName: string) {
    const navigationExtras: NavigationExtras = {
      skipLocationChange: true,
      replaceUrl: true
    };
      this.router.navigate([
        "/unit-event",
        {
          unit: JSON.stringify(unit),
          shipmentId: shipmentId,
          shipmentName: shipmentName,
        },
      ], navigationExtras);

  }

  getSwaggerApi = () => environment.apiUrl + "/docs";

  getUnitIdQrCodeData = (unitId: string) =>
    environment.qrCodeApiUrl + "/" + unitId;
}
