import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { UnitEventDto } from "../model/dto/unit-event.dto";
import { UnitDto } from "../model/dto/unit.dto";
import * as L from "leaflet";
import { MatDialog } from "@angular/material/dialog";
import { DialogMapComponent } from "../dialog-unit-map/dialog-map";
import { DialogUnitQrCodePrintComponent } from "../track-shipment/dialog-unit-qr/dialog-unit-qr";
import { UnitService } from "../services/unit.service";
import { Pagination } from "../model/pagination";
import { UserDetailOverlayComponent } from "../user-detail-overlay/user-detail-overlay.component";

const iconUrl = "assets/marker-icon.png";
const shadowUrl = "assets/marker-shadow.png";

@Component({
  selector: "app-track-unit",
  templateUrl: "./track-unit.component.html",
  styleUrls: ["./track-unit.component.css"],
})
export class TrackUnitComponent implements OnInit {
  unit: UnitDto;
  shipmentName: string;
  shipmentId: string;
  displayedColumns: string[] = [
    "timeCreated",
    "latitude",
    "longitude",
    "meshId",
    "userId",
    "action",
    "message",
    "validity",
  ];

  eventsPagination: Pagination<UnitEventDto> = <Pagination<UnitEventDto>>{};

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private _unitService: UnitService
  ) {}

  ngOnInit(): void {
    this.unit = JSON.parse(this.route.snapshot.paramMap.get("unit")) as UnitDto;
    this.shipmentName = this.route.snapshot.paramMap.get("shipmentName");
    this.shipmentId = this.route.snapshot.paramMap.get("shipmentId");

    this._unitService
      .getShipmentEventsPaginate(this.unit.id, 1)
      .subscribe((it) => {
        this.eventsPagination = it;
        this.initMap();
      });
  }

  getEventPagination($event: number) {
    this._unitService
      .getShipmentEventsPaginate(this.unit.id, $event)
      .subscribe((it) => {
        this.eventsPagination = it;
      });
  }

  private map;

  private initMap(): void {
    L.Marker.prototype.options.icon = L.icon({
      iconUrl: iconUrl,
      shadowUrl: shadowUrl,
      iconAnchor: [12, 40],
    });
    const lastLocation = this.eventsPagination.items[0].location;
    this.map = L.map("map", {
      center: [lastLocation.latitude, lastLocation.longitude],
      zoom: 6,
    });

    this.eventsPagination.items
      .map((it) => it.location)
      .forEach((it, index) => {
        if (index == 0) {
          const marker = L.marker([it.latitude, it.longitude]).addTo(this.map);
          marker
            .bindPopup("Last Known Location", { offset: L.point(0, -30) })
            .openPopup();
        } else {
          L.marker([it.latitude, it.longitude]).addTo(this.map);
        }
      });

    const tiles = L.tileLayer(
      "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      {
        maxZoom: 18,
        minZoom: 3,
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }
    );

    tiles.addTo(this.map);
  }

  enlargeMap(): void {
    this.dialog.open(DialogMapComponent, {
      data: {
        locations: this.eventsPagination.items.map((it) => it.location),
        unitId: this.unit.id,
        shipmentName: this.shipmentName,
      },
    });
  }

  showCode(): void {
    this.dialog.open(DialogUnitQrCodePrintComponent, {
      data: this.unit.id,
    });
  }

  openUserDetailOverlay(userId: string, unitId: string) {
    if (userId) {
      this.dialog.open(UserDetailOverlayComponent, {
        data: {
          unitId: unitId,
          userId: userId,
        },
      });
    }
  }
}
