import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {

  transform(value: number): string {
    const hours = Math.floor(value);
    const minutes = Math.round((value - hours) * 60);
    return `${this.padNumber(hours)}:${this.padNumber(minutes)}h`;
  }

  private padNumber(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }

}
