<mat-drawer-container class="example-container" autosize>
    <mat-drawer #drawer class="example-sidenav" mode="side">
        <form [formGroup]="shipmentSearch" (submit)="searchShipment()">
            <mat-form-field>
                <mat-label style="color: white">Shipment no...</mat-label>
                <input style="color: white" matInput type="text" formControlName="id">
            </mat-form-field>
            <button mat-button style="border-radius: 20px !important; background-color: white; color: black"
                    type="submit">Submit
            </button>
        </form>
        <span class="sidenav-item" (click)="navigateToHome()">HOME</span>
        <span class="sidenav-item" (click)="navigateToApi()"><u>API</u></span>
        <span class="sidenav-item" (click)="navigateToNewShipment()">
        <span>NEW SHIPMENT</span>
    </span>
        <span class="sidenav-item">
      <button mat-stroked-button class="sign-buttons" *ngIf="isLoggedIn" (click)="logOut()">Log Out</button>
      <button mat-stroked-button class="sign-buttons" *ngIf="!isLoggedIn" (click)="openLoginDialog()">Log In</button>
    </span>
    </mat-drawer>

    <div id="header">
        <mat-toolbar [ngClass]="isLoggedIn ? 'black-background': 'transparent-background'">
            <div class="example-sidenav-content">
                <button type="button" mat-icon-button (click)="drawer.toggle()">
                    <mat-icon [ngStyle]="{'color':'white'}">menu</mat-icon>
                </button>
            </div>
            <img id="logo" src="assets/images/logo.svg" alt="logo" (click)="navigateToHome()">
            <span class="header-menu">
      <form [formGroup]="shipmentSearch" (submit)="searchShipment()" style="margin-left: 200px">
        <mat-form-field>
          <mat-label>Shipment no...</mat-label>
          <input matInput type="text" formControlName="id">
        </mat-form-field>
        <button mat-button style="border-radius: 20px !important; background-color: white; color: black"
                type="submit">Search</button>
      </form>
    </span>
            <span class="example-spacer"></span>
            <span class="header-menu" (click)="navigateToHome()">HOME</span>
            <span class="header-menu" (click)="navigateToApi()"><u>API</u></span>
            <span class="header-menu">
      <button class="btn-new-shipment" (click)="navigateToNewShipment()">
        <img style="vertical-align: top" src="assets/images/plus-sign.svg" width="30" height="30" alt="Add Shipment"/>
        <span>NEW SHIPMENT</span>
      </button>
    </span>
            <span class="header-menu">
      <button mat-stroked-button class="sign-buttons" *ngIf="isLoggedIn" (click)="logOut()">Log Out</button>
      <button mat-stroked-button class="sign-buttons" *ngIf="!isLoggedIn" (click)="openLoginDialog()">Log In</button>
    </span>
        </mat-toolbar>
        <img id="logo-background" *ngIf="isLoggedIn" src="assets/images/logo_background_rectangle.svg"
             alt="logo_background"
             style="margin-top: -12px">
    </div>


</mat-drawer-container>