<div>
  <div class='content'>
    <div class='intro' *ngIf='!isLoggedIn'>

      <div class='row'>
        <div style='width: 45%; float:left; text-align: right'>
          <p style='font-size: 34px'>Track & Trust</p>
          <p style='font-size: 23px'>Your shipment's guardian angel</p>
        </div>
        <div style='width: 45%; float:right'>
          <p>Make sure your shipments arrive safe and sound with Track & Trust, the most advanced satellite-based last
            mile tracking solution elevating blockchain technology for unmatched security. With Track & Trust you will
            be
            able to follow shipments in disaster areas and areas of conflict.</p>
        </div>
      </div>

      <div class='row'>
        <div style='width: 45%; float:left; text-align: left'>
          <img src='assets/images/home-icons/goggles.png' style='float: left; padding-top: 20px' alt='goggles'>
          <p style='float: right; width: 90%'><b>Last mile tracking with no blind spots</b> <br>
            The advanced tracking
            technology of
            Track & Trust
            enables you to stay
            updated on the location of your shipments, even when the network connection is weak or non-existent. </p>
        </div>
        <div style='width: 45%; float:right; text-align: left'>
          <img src='assets/images/home-icons/analytics.png' style='float: left; padding-top: 20px' alt='analytics'>
          <p style='float: right; width: 90%'><b>Real-time analytics, right at your fingertips </b> <br>
            Gain insights into your shipments like never before with our easy-to-use dashboard that displays detailed
            analytics in real time.</p>
        </div>
      </div>

      <div class='row'>
        <div style='width: 45%; float:left; text-align: left'>
          <img src='assets/images/home-icons/link.png' style='float: left; padding-top: 20px' alt='goggles'>
          <p style='float: right; width: 90%'><b>Secure data with blockchain technology</b> <br>
            Our blockchain technology ensures
            all data is securely stored and encrypted, protecting your sensitive shipment details from any unauthorized
            access.</p>
        </div>
        <div style='width: 45%; float:right; text-align: left'>
          <img src='assets/images/home-icons/mark-point.png' style='float: left; padding-top: 20px' alt='analytics'>
          <p style='float: right; width: 90%'><b>Peace of mind – wherever you deliver to</b> <br>
            Our state-of-the-art mobile app
            puts all the power of Track & Trust in the hands of anyone who processes your shipments so you can monitor
            them anytime, anywhere!</p>
        </div>
      </div>

    </div>

    <div>
      <h1 *ngIf="isLoggedIn">Home</h1>
      <h4 *ngIf="isLoggedIn">Recent Shipment Activity - Last {{nDays}} days</h4>
      <div *ngFor='let map of shipmentEventDtos; index as i' >
        <app-home-shipment-box [shipmentEventDto]='map' [nDays]=nDays.toString() [index]='i.toString()'></app-home-shipment-box>
      </div>

      <br>
      <h4 *ngIf="isLoggedIn">Older Shipments</h4>
      <div *ngFor='let map of olderShipmentEventDtos; index as i' >
          <app-home-shipment-box [shipmentEventDto]='map' [index]='"Combined"+i.toString()'></app-home-shipment-box>
      </div>
    </div>
  </div>
</div>