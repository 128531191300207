<div class="content">
    <form style="text-align:left; width: 350px;" [formGroup]="unitForm"
          (submit)="submit()">
        <div class="myContainer">
            <p>Enter Unit Data</p>
            <button mat-raised-button color="primary" type="submit" [disabled]="!unitForm.valid">CREATE UNIT</button>
        </div>

        <input id="id" matInput type="text" formControlName="id" placeholder="ID *">

    </form>
</div>