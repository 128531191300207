import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { UnitEventDto } from "../model/dto/unit-event.dto";
import { Observable } from "rxjs";
import { Pagination } from "../model/pagination";
import { MyHttpParam } from "../model/http-param";
import { UnitCreateDto } from "../model/dto/unit-create.dto";
import { UnitDto } from "../model/dto/unit.dto";
import { UserActionDetailResponse } from "../model/response/user-action-detail.response";

@Injectable({
  providedIn: "root",
})
export class UnitService {
  baseUrl = environment.apiUrl;

  constructor(private _httpClient: HttpClient) {}

  getFirstNEventsForUnits(unitIds: string[], limit: number) {
    let params = new HttpParams();
    if (unitIds.length == 1) unitIds.push("");

    params = params.appendAll({ unitIds: unitIds });
    params = params.append("limit", limit);
    return this._httpClient.get<UnitEventDto[]>(
      `${this.baseUrl}/units/first-events`,
      { params: params }
    );
  }

  getShipmentEventsPaginate(
    unitId: string,
    page: number,
    limit?: number
  ): Observable<Pagination<UnitEventDto>> {
    const myParams: MyHttpParam[] = [
      { name: "page", value: page },
      { name: "limit", value: limit ?? 20 },
    ];
    let params = new HttpParams();
    myParams.forEach((it) => (params = params.append(it.name, it.value)));
    params = params.append("unitId", unitId);
    return this._httpClient.get<Pagination<UnitEventDto>>(
      `${this.baseUrl}/units/event-paginate`,
      { params: params }
    );
  }

  createUnit = (dto: UnitCreateDto): Observable<UnitDto> =>
    this._httpClient.post<UnitDto>(`${this.baseUrl}/units/create`, dto);

  getUserDetailsWithActionEvents = (
    userId: string,
    unitId: string
  ): Observable<UserActionDetailResponse> =>
    this._httpClient.get<UserActionDetailResponse>(
      `${this.baseUrl}/units/details/${userId}/${unitId}`
    );
}
