import { Component } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "dialog-info",
  templateUrl: "dialog-info.html",
})
export class DialogInfo {
  public title: String;
  public desc: String;

  constructor(
    public dialogRef: MatDialogRef<DialogInfo>,
    public dialog: MatDialog
  ) {}

  close = () => {
    this.dialogRef.close();
  };
}
