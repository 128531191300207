<div mat-dialog-content>
    <div style='display: flex'>
    <p *ngIf='data.shipmentName' style='margin-right: 15px'>Shipment Name: {{data.shipmentName}}</p>
    <p *ngIf='data.unitId'>Unit ID: {{data.unitId}}</p>
    </div>
    <div class="map-container">
        <div class="map-frame">
            <div id="map-dialog"></div>
        </div>
    </div>
</div>