<div class="content">
    <div class="myContainer">
        <h5 style="align-self: start">Live view of outgoing API</h5>
        <a style="align-self: center;" href="{{getSwaggerApi()}}">Go to Swagger API</a>
    </div>

    <textarea id="myTextArea" cols=50 rows=10 *ngIf="textArea">{{textArea}}</textarea>

    <h3 style="align-self: start">Calls by external logistics system/frontend</h3>
    <div *ngFor="let shipment of shipments" class="shipment-box">
        <h4 class="shipment-name" style="margin-top: 30px" (click)="navigateToShipment(shipment.id)">Shipment
            name: {{shipment.name}}
            (ID: {{shipment.id}})</h4>
        <div class="units-box">
            <div class="qr-code" *ngFor="let unit of shipment.units"
                 (click)="navigateToUnit(unit, shipment.id, shipment.name)">

                <qrcode [qrdata]=getUnitIdQrCodeData(unit.id) [width]="150"
                        [errorCorrectionLevel]="'M'"></qrcode>
                <h6 style="margin: 0; width: 250px">{{unit.id}}</h6>

            </div>
        </div>
    </div>
</div>
