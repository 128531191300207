import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, map, Observable, tap} from "rxjs";
import {AccessTokenDto} from "../../model/dto/access-token.dto";
import {AccessTokenDecodedDto} from "../../model/dto/access-token-decoded.dto";
import jwtDecode from "jwt-decode";
import {LoginDto} from "../../model/dto/login.dto";
import {RegisterDto} from "../../model/dto/register.dto";

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    baseLoginUrl = environment.apiUrl + "/auth/login"
    baseRegisterUrl = environment.apiUrl + "/auth/register"

    private _isLoggedIn = new BehaviorSubject<boolean>(false);

    constructor(private _httpClient: HttpClient) {
    }

    login = (loginValue: LoginDto) =>
        this._httpClient.post<AccessTokenDto>(this.baseLoginUrl, loginValue)
            .pipe(
                tap(result => {
                    this.setSession(result)
                    this._isLoggedIn.next(true)
                })
            )

    register(registrationData: RegisterDto) {
        debugger;
        return this._httpClient.post(this.baseRegisterUrl, registrationData)
    }

    setSession(authResult: AccessTokenDto) {
        const jwtDecoded: AccessTokenDecodedDto = jwtDecode(authResult.access_token)

        localStorage.setItem('access_token', authResult.access_token);
        localStorage.setItem('expires_in', jwtDecoded.exp);
    }

    logout() {
        localStorage.removeItem('access_token')
        localStorage.removeItem('expires_in')
    }

    loggedIn(): Observable<boolean> {
        return this._isLoggedIn.asObservable()
            .pipe(
                map(() => {
                    const expiration = localStorage.getItem("expires_in");

                    if (!expiration) return false;

                    const dateNow = new Date().getTime() / 1000
                    if (dateNow < +expiration)
                        return true
                    else {
                        this.logout()
                        return false
                    }
                })
            )
    }

    getUserRoles(): string[] {
        const accessToken = localStorage.getItem('access_token')
        if (accessToken) {
            const jwtDecoded: AccessTokenDecodedDto = jwtDecode(accessToken)
            return jwtDecoded.roles
        }
        return []
    }

    getUsername(): string {
        const accessToken = localStorage.getItem('access_token')
        if (accessToken) {
            const jwtDecoded: AccessTokenDecodedDto = jwtDecode(accessToken)
            return jwtDecoded.name
        }
        return ''
    }

}