
<div class="content">
    <h2>KPI's for shipment: {{ shipmentId }}</h2>

    <table>
    <tr>
        <td>
            <h3 *ngIf="unitActionEventsPerShipment !== undefined">{{ unitActionEventsPerShipment }}
                </h3>
            <h3 *ngIf="unitActionEventsPerShipment === undefined">N/A</h3>
            <p>Total action events per shipment</p>
        </td>
        <td>
            <h3>{{numberOfPrintedQrCodes ?? 0}}</h3>
            <p>Number of QR codes printed</p>
        </td>
        <td>
            <h3 *ngIf="unitActionEventsPerShipment !== undefined">{{ unitActionEventsPerShipment }}
                %</h3>
            <h3 *ngIf="unitActionEventsPerShipment === undefined">N/A</h3>
            <p>Total action events per shipment</p>
        </td>
    </tr>
</table>
</div>