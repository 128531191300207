import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {KpiService} from "../services/kpi.service";
import {ActivatedRoute} from "@angular/router";
import {forkJoin} from "rxjs";

@Component({
    selector: 'app-kpi-shipment',
    templateUrl: './kpi-shipment.component.html',
    styleUrls: ['./kpi-shipment.component.css']
})
export class KpiShipmentComponent implements OnInit, OnChanges {
    @Input() shipmentId: string
    unitActionEventsPerShipment: number
    numberOfPrintedQrCodes: number = 0

    constructor(private _kpiService: KpiService) {
    }

    ngOnInit(): void {
        this.loadActionEventsPerShipment()
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['shipmentId'] && changes['shipmentId'].currentValue) {
            this.loadActionEventsPerShipment();
        }
    }

    private loadActionEventsPerShipment(): void {
        // forkJoin([
        //     this._kpiService.getActionEventsPerShipment(this.shipmentId),
        //     this._kpiService.getNumberOfPrintedQrCodes()
        // ])
        //     .subscribe(([totalActionEventsPerShipment, numberOfPrintedQrCodes]) => {
        //             this.unitActionEventsPerShipment = totalActionEventsPerShipment
        //             this.numberOfPrintedQrCodes = +numberOfPrintedQrCodes.kpiValue
        //         }
        //     );
    }
}
