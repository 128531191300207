<div class="content">
    <form class="myContainer" [formGroup]="meshNodeIdForm"
          (submit)="submit()">
        <input id="id" matInput type="text" formControlName="id" placeholder="ID *" style="width: 80%">
        <button mat-raised-button color="primary" type="submit">Search</button>
    </form>

    <table mat-table [dataSource]="eventsPagination.items" class="mat-elevation-z8">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <ng-container matColumnDef="timeCreated">
            <th mat-header-cell *matHeaderCellDef>Time of Arrival in Main Server</th>
            <td mat-cell *matCellDef="let element"> {{element.timeCreated}} </td>
        </ng-container>
        <ng-container matColumnDef="timestamp">
            <th mat-header-cell *matHeaderCellDef>Time Created</th>
            <td mat-cell *matCellDef="let element"> {{element.timestamp}} </td>
        </ng-container>
        <ng-container matColumnDef="battery">
            <th mat-header-cell *matHeaderCellDef>Battery</th>
            <td mat-cell *matCellDef="let element"> {{element.battery}} </td>
        </ng-container>
        <ng-container matColumnDef="memory">
            <th mat-header-cell *matHeaderCellDef>Free memory (KB)</th>
            <td mat-cell *matCellDef="let element"> {{element.memory}} </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <app-pager
            [totalItems]="eventsPagination?.meta?.totalItems"
            (newItemEvent)="getEventPagination($event)">
    </app-pager>
</div>