import { AuthService } from "../services/auth/auth.service";
import { ShipmentService } from "../services/shipment.service";
import { ShipmentEventDto } from "../model/dto/shipment-event.dto";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  nDays: number = 7;
  isLoggedIn: boolean = false;
  shipmentEventDtos: ShipmentEventDto[];
  olderShipmentEventDtos: ShipmentEventDto[];

  constructor(
    private _authService: AuthService,
    private _shipmentService: ShipmentService
  ) {}

  ngOnInit(): void {
    this._authService.loggedIn().subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
      if (isLoggedIn) this.getRecentShipments();
    });
  }

  getRecentShipments() {
    this._shipmentService
      .getAllShipmentUpdatedInTheLastNDays(this.nDays)
      .subscribe((next) => {
        this.shipmentEventDtos = next.newShipments;
        this.olderShipmentEventDtos = next.olderShipments;
      });
  }
}
