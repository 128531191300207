import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MeshNodeStatusEventService } from "../services/mesh-node-status-event.service";
import { Pagination } from "../model/pagination";
import { MeshNodeStatusEventResponse } from "../model/response/mesh-node-status-event.response";

@Component({
  selector: "app-mesh-node-status-event",
  templateUrl: "./mesh-node-status-event.component.html",
  styleUrls: ["./mesh-node-status-event.component.css"],
})
export class MeshNodeStatusEventComponent implements OnInit {
  meshNodeId: string;
  meshNodeIdForm: FormGroup = new FormGroup({
    id: new FormControl("", [Validators.required]),
  });
  eventsPagination: Pagination<MeshNodeStatusEventResponse> = <
    Pagination<MeshNodeStatusEventResponse>
  >{};
  displayedColumns: string[] = [
    "battery",
    "memory",
    "timeCreated",
    "timestamp",
  ];

  constructor(private _meshNodeStatusService: MeshNodeStatusEventService) {}

  submit() {
    this.meshNodeId = this.meshNodeIdForm.get("id").value;
    this._meshNodeStatusService
      .getMeshNodeEventsPaginate(this.meshNodeId, 1)
      .subscribe(
        (meshNodeStatusResponse) =>
          (this.eventsPagination = meshNodeStatusResponse)
      );
  }

  getEventPagination($event: number) {
    this._meshNodeStatusService
      .getMeshNodeEventsPaginate(this.meshNodeId, $event)
      .subscribe((it) => {
        this.eventsPagination = it;
      });
  }

  ngOnInit(): void {}
}
