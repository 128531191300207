<div class="content">
    <form style="text-align:left; width: fit-content; block-size: fit-content;" [formGroup]="shipmentForm"
          (submit)="submit()">
        <p>Enter Shipment Data</p>


        <input id="id" matInput type="text" formControlName="id" placeholder="ID *">

        <input id="name" matInput type="text" formControlName="name" placeholder="Name *">

        <div class="myContainer">
            <br>
            <button *ngIf="!createdShipment" mat-raised-button color="primary" type="button"
                    [disabled]="!shipmentForm.valid" (click)="createShipment()">CREATE SHIPMENT
            </button>
        </div>


        <div *ngIf="createdShipment">
            <p>Add Unit</p>
            <div formArrayName="containers">
                <div *ngFor="let container of containers.controls; index as i" class="myContainer">
                    <input type="text" matInput [formControlName]="i" placeholder="Unit *" style="width: 80%">

                    <img class="delete-container" src="assets/images/trash-can.svg" (click)="removeContainer(i)"
                         alt="Delete container {{i}}">
                </div>
            </div>
        </div>

        <div class="myContainer">
            <br>
            <button *ngIf="createdShipment" type="button" style="margin-bottom: 15px" mat-raised-button color="primary"
                    (click)="addUnit()">ADD UNIT
            </button>
        </div>

        <button mat-raised-button color="primary" type="submit" [disabled]="!shipmentForm.valid"
                [hidden]="!createdShipment" *ngIf="createdShipment">SAVE SHIPMENT
        </button>

    </form>
</div>