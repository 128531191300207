import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { Pagination } from "../model/pagination";
import { MyHttpParam } from "../model/http-param";
import { MeshNodeStatusEventResponse } from "../model/response/mesh-node-status-event.response";

@Injectable({
  providedIn: "root",
})
export class MeshNodeStatusEventService {
  baseUrl = environment.apiUrl;

  constructor(private _httpClient: HttpClient) {}

  getMeshNodeEventsPaginate(
    meshNodeId: string,
    page: number,
    limit?: number
  ): Observable<Pagination<MeshNodeStatusEventResponse>> {
    const myParams: MyHttpParam[] = [
      { name: "page", value: page },
      { name: "limit", value: limit ?? 20 },
    ];
    let params = new HttpParams();
    myParams.forEach((it) => (params = params.append(it.name, it.value)));
    params = params.append("meshNodeId", meshNodeId);
    return this._httpClient.get<Pagination<MeshNodeStatusEventResponse>>(
      `${this.baseUrl}/mesh-node-status/event-paginate`,
      { params: params }
    );
  }
}
