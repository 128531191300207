<form [formGroup]="registerForm" (submit)="submit()">
    <h2>Registration</h2>

    <label for="user-name">Username: </label>
    <input id="user-name" type="text" formControlName="username">

    <label for="password">Password: </label>
    <input id="password" type="password" formControlName="password">

    <button type="submit">Submit</button>

</form>