import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RegisterComponent } from "./register/register.component";
import { HomeComponent } from "./home/home.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { CreateShipmentComponent } from "./create-shipment/create-shipment.component";
import { TrackShipmentComponent } from "./track-shipment/track-shipment.component";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { AuthInterceptor } from "./services/auth/auth-interceptor.service";
import { HeaderComponent } from "./header/header.component";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatTableModule } from "@angular/material/table";
import { TrackUnitComponent } from "./track-unit/track-unit.component";
import { MatInputModule } from "@angular/material/input";
import { QRCodeModule } from "angularx-qrcode";
import { NgxPrintModule } from "ngx-print";
import { MatDialogModule } from "@angular/material/dialog";
import { DialogUnitQrCodePrintComponent } from "./track-shipment/dialog-unit-qr/dialog-unit-qr";
import { DialogMapComponent } from "./dialog-unit-map/dialog-map";
import { DialogLoginComponent } from "./header/dialog-login/dialog-login";
import { HomeShipmentBoxComponent } from "./home-shipment-box/home-shipment-box.component";
import { PagerService } from "./pager/pager.service";
import { PagerComponent } from "./pager/pager.component";
import { CreateUnitComponent } from "./create-unit/create-unit.component";
import { ApiComponent } from "./api/api.component";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MeshNodeStatusEventComponent } from "./mesh-node-status-event/mesh-node-status-event.component";
import { KpiComponent } from "./kpi/kpi.component";
import { DialogInfo } from "./shared/dialogs/dialog-info/dialog-info";
import { UserDetailOverlayComponent } from "./user-detail-overlay/user-detail-overlay.component";
import { MatListModule } from "@angular/material/list";
import { KpiShipmentComponent } from './kpi-shipment/kpi-shipment.component';
import {NgOptimizedImage} from "@angular/common";
import { IntegerPipe } from './pipes/integer.pipe';
import { TimeFormatPipe } from './pipes/time-format.pipe';

@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    HomeComponent,
    HeaderComponent,
    CreateShipmentComponent,
    TrackShipmentComponent,
    DialogUnitQrCodePrintComponent,
    DialogMapComponent,
    DialogLoginComponent,
    TrackUnitComponent,
    HomeShipmentBoxComponent,
    PagerComponent,
    CreateUnitComponent,
    ApiComponent,
    MeshNodeStatusEventComponent,
    KpiComponent,
    DialogInfo,
    UserDetailOverlayComponent,
    KpiShipmentComponent,
    IntegerPipe,
    TimeFormatPipe,
  ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        MatTableModule,
        MatInputModule,
        QRCodeModule,
        NgxPrintModule,
        MatDialogModule,
        MatSidenavModule,
        MatListModule,
        FormsModule,
        NgOptimizedImage,
    ],
  providers: [
    PagerService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
