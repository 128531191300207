<div class="content">
    <h2>KPI table</h2>
    <tr>
        <td>Objective #</td>
        <td>Objective</td>
        <td>KPI</td>
        <td>KPI Description</td>
        <td>Expected output</td>
        <td>Shipment 1, ID: LE03_QVEJ00021386</td>
        <td>Shipment 2, ID: LE04</td>
        <td>Shipment 3, ID: LE05</td>
    </tr>
    <tr>
        <td>1.1</td>
        <td rowspan="2">Provide traceability (of shipment)</td>
        <td>Quantity of data received</td>
        <td>Number of unique status updates per shipment</td>
        <td>Status updates per shipment should be greater than 24</td>
        <td>{{ getKPIValue(kpiShipmentOne, 'NUMBER_OF_UNIQUE_STATUS_UPDATES') }}</td>
        <td> {{ unitActionEventsForShipments[1].totalNumberOfActionEvents }}</td>
        <td> {{ unitActionEventsForShipments[2].totalNumberOfActionEvents }}</td>
    </tr>
    <tr>
        <td>1.2</td>
        <td>Number of units tracked</td>
        <td>Number of Unique QR Codes deployed on pallets</td>
        <td>12 per shipment</td>
        <td> {{ getKPIValue(kpiShipmentOne, 'NUMBER_OF_QR_CODES') }}</td>
        <td> {{ getKPIValue(kpiShipmentTwo, 'NUMBER_OF_QR_CODES') }}</td>
        <td> {{ getKPIValue(kpiShipmentThree, 'NUMBER_OF_QR_CODES') }}</td>
    </tr>
    <tr>
        <td>2.1</td>
        <td rowspan="2">Provide trustful & authentic (shipment) information</td>
        <td>Ratio signed and unsigned data in the backend</td>
        <td>Divide the number of validated status updates by the number of unvalidated status updates received on the
            backend.
        </td>
        <td>Signed data should be greater than 50% of all data</td>
        <td>{{ dataIntegrityAndSecurityPercentage[0].percentage | integer }}%</td>
        <td>{{ dataIntegrityAndSecurityPercentage[1].percentage | integer }}%</td>
        <td>{{ dataIntegrityAndSecurityPercentage[2].percentage | integer }}%</td>
    </tr>
    <tr>
        <td>2.2</td>
        <td>Up-to-dateness of shipment information</td>
        <td>Average difference in time between action event generation and posting of event at backend per shipment</td>
        <td>Less than 24 hours</td>
        <td>
            Hours: {{ getKPIValue(kpiShipmentOne, 'UP_TO_DATENESS_HOURS') }}
        </td>
        <td>
            Hours: {{ getKPIValue(kpiShipmentTwo, 'UP_TO_DATENESS_HOURS') }}
        </td>
        <td>Hours: {{ +eventTransferDelayers[2].seconds | timeFormat }}
    </tr>
    <tr>
        <td>3.1</td>
        <td rowspan="3">Provide connectivity in remote areas</td>
        <td>Mesh network uptime</td>
        <td>This percentage figure shows how much of the time during the course of the shipment that nodes were
            available at the service level required.
        </td>
        <td>Should be greater than 50%</td>
        <td>{{ meshNodesOnlinePercentage[0].percentage | integer }}%</td>
        <td>{{ meshNodesOnlinePercentage[1].percentage | integer }}%</td>
        <td>{{ meshNodesOnlinePercentage[2].percentage | integer }}%</td>
    </tr>
    <tr>
        <td>3.2</td>
        <td>Uptime for blockchain protocol</td>
        <td>% of time that blockchain protocol was up and running per shipment</td>
        <td>Should be 99% or better</td>
        <td> {{ getKPIValue(kpiShipmentOne, 'BLOCKCHAIN_UPTIME') }}%</td>
        <td> {{ getKPIValue(kpiShipmentTwo, 'BLOCKCHAIN_UPTIME') }}%</td>
        <td> {{ getKPIValue(kpiShipmentThree, 'BLOCKCHAIN_UPTIME') }}</td>
    </tr>
    <tr>
        <td>3.3</td>
        <td>Uptime for management platform. (AWS)</td>
        <td>% of time that management platform was up and running per shipment</td>
        <td>Should be 99% or better</td>
        <td> {{ getKPIValue(kpiShipmentOne, 'AMAZON_FRONT_END_UPTIME') }}%</td>
        <td> {{ getKPIValue(kpiShipmentTwo, 'AMAZON_FRONT_END_UPTIME') }}%</td>
        <td> {{ getKPIValue(kpiShipmentThree, 'AMAZON_FRONT_END_UPTIME') }}</td>
    </tr>
    <tr>
        <td>4.1</td>
        <td rowspan="3">
            Achieve user satisfaction
        </td>
        <td>Management platform authorised access</td>
        <td>Number of accepted logins per shipment</td>
        <td>Number greater than 10</td>
        <td>{{ getKPIValue(kpiShipmentOne, 'NUMBER_OF_ACCEPTED_LOGINS') }}</td>
        <td>{{ getKPIValue(kpiShipmentTwo, 'NUMBER_OF_ACCEPTED_LOGINS') }}</td>
        <td>{{ getKPIValue(kpiShipmentThree, 'NUMBER_OF_ACCEPTED_LOGINS') }}</td>
    </tr>
    <tr>
        <td>4.2</td>
        <td>Management platform unauthorised accesses</td>
        <td>Number of rejected logins per shipment</td>
        <td>Number of rejected logins to management platform should be greater than 1</td>
        <td>{{ getKPIValue(kpiShipmentOne, 'NUMBER_OF_REJECTED_LOGINS') }}</td>
        <td>{{ getKPIValue(kpiShipmentTwo, 'NUMBER_OF_REJECTED_LOGINS') }}</td>
        <td>{{ getKPIValue(kpiShipmentThree, 'NUMBER_OF_REJECTED_LOGINS') }}</td>
    </tr>
    <tr>
        <td>4.3</td>
        <td>User Satisfaction and Usability</td>
        <td>Average satisfaction score on a questionnaire</td>
        <td>Satisfaction rated 7/10 or above on a 10 point scale</td>
        <td> {{ getKPIValue(kpiShipmentOne, 'QUESTIONNAIRE_KPI') }}</td>
        <td> {{ getKPIValue(kpiShipmentTwo, 'QUESTIONNAIRE_KPI') }}</td>
        <td> {{ getKPIValue(kpiShipmentThree, 'QUESTIONNAIRE_KPI') }}</td>
    </tr>
    <tr>
        <td>5.1</td>
        <td rowspan="3">Endure harsh conditions</td>
        <td>Battery Failures</td>
        <td>Percentage of deployed mesh nodes with unrecoverable error due to battery problems</td>
        <td>Less than 40%</td>
        <td> {{ getKPIValue(kpiShipmentOne, 'BATTERY_FAILURE_KPI') }}%</td>
        <td> {{ getKPIValue(kpiShipmentTwo, 'BATTERY_FAILURE_KPI') }}%</td>
        <td> {{ getKPIValue(kpiShipmentThree, 'BATTERY_FAILURE_KPI') }}</td>
    </tr>
    <tr>
        <td>5.2</td>
        <td>Hardware Reliability</td>
        <td>Percentage of deployed mesh nodes with unrecoverable failure requiring replacement</td>
        <td>Less than 40%</td>
        <td> {{ getKPIValue(kpiShipmentOne, 'HARDWARE_RELIABILITY_KPI') }}%</td>
        <td> {{ getKPIValue(kpiShipmentTwo, 'HARDWARE_RELIABILITY_KPI') }}%</td>
        <td> {{ getKPIValue(kpiShipmentThree, 'HARDWARE_RELIABILITY_KPI') }}</td>
    </tr>
    <tr>
        <td>5.3</td>
        <td>Temperature related failures</td>
        <td>Number of times temperature readings reached over 70 degrees requiring shutdown</td>
        <td>Less than 1x per shipment</td>
        <td> {{ getKPIValue(kpiShipmentOne, 'TEMPERATURE_FAILURES_KPI') }}</td>
        <td> {{ getKPIValue(kpiShipmentTwo, 'TEMPERATURE_FAILURES_KPI') }}</td>
        <td> {{ getKPIValue(kpiShipmentThree, 'TEMPERATURE_FAILURES_KPI') }}</td>
    </tr>
    <tr>
        <td>6.1</td>
        <td>Provide SatCom communication</td>
        <td>Uptime satellite communication</td>
        <td>Number of atomic data packages delivered via OroraTech’s satcom API</td>
        <td> 5 or more messages per shipment.</td>
        <td> {{ getKPIValue(kpiShipmentOne, 'SATCOM_MESSAGES_KPI') }}</td>
        <td> {{ getKPIValue(kpiShipmentTwo, 'SATCOM_MESSAGES_KPI') }}</td>
        <td> {{ getKPIValue(kpiShipmentThree, 'SATCOM_MESSAGES_KPI') }}</td>
    </tr>
    <tr>
        <td>7.1</td>
        <td>Green KPIs</td>
        <td>CO2 emissions saved (%)</td>
        <td>Elimination of cargo loss (4%)</td>
        <td>> 3 kg</td>
        <td> {{ getKPIValue(kpiShipmentOne, 'CO2_SAVED_KPI') }}kg</td>
        <td> {{ getKPIValue(kpiShipmentTwo, 'CO2_SAVED_KPI') }}kg</td>
        <td> {{ getKPIValue(kpiShipmentThree, 'CO2_SAVED_KPI') }}</td>
    </tr>
    <tr>
        <td>7.2</td>
        <td>Green KPIs</td>
        <td>Diesel savings (litre)</td>
        <td>Elimination of cargo loss (4%)</td>
        <td>> 1 litre</td>
        <td> {{ getKPIValue(kpiShipmentOne, 'DIESEL_SAVED_KPI') }} litre</td>
        <td> {{ getKPIValue(kpiShipmentTwo, 'DIESEL_SAVED_KPI') }} litre</td>
        <td> {{ getKPIValue(kpiShipmentThree, 'DIESEL_SAVED_KPI') }}</td>
    </tr>
</div>