import {Component, OnInit} from '@angular/core';
import {KpiService} from "../services/kpi.service";
import {FetchEventTransferDelayResponse} from "../model/response/fetch-event-transfer-delay.response";
import {zip} from "rxjs";
import {Router} from "@angular/router";
import {ShipmentService} from "../services/shipment.service";
import {ShipmentDto} from "../model/dto/shipment.dto";
import {TotalActionEventsForShipmentResponse} from "../model/response/total-action-events-for-shipment.response";
import {VerifiedEventPercentageResponse} from "../model/response/verified-event-percentage.response";
import {KpiShipmentResponse} from "../model/response/kpi-shipment.response";

@Component({
    selector: 'app-kpi',
    templateUrl: './kpi.component.html',
    styleUrls: ['./kpi.component.css']
})
export class KpiComponent implements OnInit {
    shipments: ShipmentDto[]

    meshNodesOnlinePercentage?: VerifiedEventPercentageResponse[]
    eventTransferDelayers: FetchEventTransferDelayResponse[] = []
    dataIntegrityAndSecurityPercentage?: VerifiedEventPercentageResponse[]
    totalSuccessfulLogins?: number
    totalFailedLogins?: number

    unitActionEventsForShipments: TotalActionEventsForShipmentResponse[]
    numberOfPrintedQrCodes: number = 0

    kpiShipmentOne: KpiShipmentResponse
    kpiShipmentTwo: KpiShipmentResponse
    kpiShipmentThree: KpiShipmentResponse

    constructor(
        private _kpiService: KpiService,
        private _shipmentService: ShipmentService,
        private router: Router,
    ) {
    }

    ngOnInit(): void {
        zip([
            this._kpiService.fetchEventTransferDelay(['LE03_QVEJ00021386', 'LE04', 'LE05']),
            this._kpiService.getMeshNodeOnlinePercentage(['LE03_QVEJ00021386', 'LE04', 'LE05']),
            this._kpiService.getVerifiedEventPercentage(['LE03_QVEJ00021386', 'LE04', 'LE05']),
            this._shipmentService.getAllShipments(),
            this._kpiService.getSuccessfulNumberOfLogins(),
            this._kpiService.getFailedNumberOfLogins(),
            this._kpiService.getActionEventsPerShipment(['LE03_QVEJ00021386', 'LE04', 'LE05']),
            this._kpiService.getKpiShipmentById('shipment1'),
            this._kpiService.getKpiShipmentById('shipment2'),
            this._kpiService.getKpiShipmentById('shipment3')
        ]).subscribe(([
                          eventTransferDelayers,
                          meshNodesOnlinePercentage,
                          verifiedEventPercentage,
                          shipments,
                          successfulNumberOfLogins,
                          failedNumberOfLogins,
                          unitActionEventsForShipment,
                          kpiShipmentOne,
                          kpiShipmentTwo,
                          kpiShipmentThree,
                      ]) => {
            this.shipments = shipments
            this.meshNodesOnlinePercentage = meshNodesOnlinePercentage
            this.dataIntegrityAndSecurityPercentage = verifiedEventPercentage
            this.eventTransferDelayers = eventTransferDelayers
            this.totalSuccessfulLogins = successfulNumberOfLogins
            this.totalFailedLogins = failedNumberOfLogins
            this.unitActionEventsForShipments = unitActionEventsForShipment
            this.kpiShipmentOne = kpiShipmentOne
            this.kpiShipmentTwo = kpiShipmentTwo
            this.kpiShipmentThree = kpiShipmentThree
        })
    }

    getKPIValue(kpis: KpiShipmentResponse, keyPrefix: string): string {
        const kpi = kpis.kpiEntities.find(k => k.kpiKey.startsWith(keyPrefix));

        return kpi.kpiValue;
    }
}
