import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {GpsLocation} from "../model/dto/unit-event.dto";
import * as L from 'leaflet';

const iconUrl = "assets/marker-icon.png";
const shadowUrl = "assets/marker-shadow.png";

@Component({
    selector: 'dialog-unit-map',
    templateUrl: 'dialog-map.html',
    styleUrls: ['dialog-map.css'],
})
export class DialogMapComponent implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { locations: GpsLocation[], unitId?: string, shipmentName?: string },
    ) {

    }

    ngOnInit(): void {
        this.initMap()
    }

    private mapDialog;

    private initMap(): void {

      L.Marker.prototype.options.icon = L.icon({
        iconUrl: iconUrl,
        shadowUrl: shadowUrl,
      });

        const lastLocation = this.data.locations[this.data.locations.length - 1]
        this.mapDialog = L.map('map-dialog', {
            center: [lastLocation.latitude, lastLocation.longitude],
            zoom: 6
        });

        this.data.locations.forEach(it => {
            L.marker([it.latitude, it.longitude]).addTo(this.mapDialog)
        })

        const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 18,
            minZoom: 3,
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        });

        tiles.addTo(this.mapDialog);
    }
}