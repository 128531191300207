import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { ShipmentEventDto } from "../model/dto/shipment-event.dto";
import * as L from "leaflet";
import { UnitDataset } from "../model/dto/unit-event.dto";
import { Router } from "@angular/router";

const iconUrl = "assets/marker-icon.png";
const shadowUrl = "assets/marker-shadow.png";

@Component({
  selector: "app-home-shipment-box",
  templateUrl: "./home-shipment-box.component.html",
  styleUrls: ["./home-shipment-box.component.css"],
})
export class HomeShipmentBoxComponent implements OnInit, AfterViewInit {
  @Input() shipmentEventDto: ShipmentEventDto;
  // if null just show te total events for the shipment
  @Input() nDays?: string;
  @Input() index: string;

  constructor(private router: Router) {}

  private map;

  ngAfterViewInit(): void {
    const dynamicElementId = `map${this.index}`;
    const dynamicElement = document.getElementById(dynamicElementId);
    const eventLength = this.shipmentEventDto.unitEvents.length;

    L.Marker.prototype.options.icon = L.icon({
      iconUrl: iconUrl,
      shadowUrl: shadowUrl,
      iconAnchor: [12, 40],
    });

    if (eventLength != 0) {
      const lastLocation =
        this.shipmentEventDto.unitEvents[eventLength - 1].location;

      this.map = L.map(dynamicElement, {
        center: [lastLocation.latitude, lastLocation.longitude],
        zoom: 6,
      });

      this.shipmentEventDto.unitEvents.forEach((event, index) => {
        // last known location
        if (index === eventLength - 1) {
          L.marker([lastLocation.latitude, lastLocation.longitude])
            .addTo(this.map)
            .bindPopup("Last Known Location", { offset: L.point(0, -30) })
            .openPopup();
        } else {
          L.marker([event.location.latitude, event.location.longitude]).addTo(
            this.map
          );
        }
      });

      const tiles = L.tileLayer(
        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        {
          maxZoom: 18,
          minZoom: 3,
          attribution:
            '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        }
      );

      tiles.addTo(this.map);
    }
  }

  ngOnInit(): void {}

  castDatasetToUnitDataset = (value: any) => value as UnitDataset;

  navigateToShipment(id: string) {
    this.router.navigate([`/shipment/${id}`]);
  }
}
