import { Component, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { ShipmentService } from "../services/shipment.service";
import { ShipmentCreateDto } from "../model/dto/shipment-create.dto";
import { Router } from "@angular/router";
import { AuthService } from "../services/auth/auth.service";

@Component({
  selector: "app-create-shipment",
  templateUrl: "./create-shipment.component.html",
  styleUrls: ["./create-shipment.component.css"],
})
export class CreateShipmentComponent implements OnInit {
  shipmentForm: FormGroup = new FormGroup({
    id: new FormControl("", [Validators.required]),
    name: new FormControl("", [Validators.required]),
    containers: new FormArray([]),
  });

  createdShipment: boolean = false;

  constructor(
    private router: Router,
    private _shipmentService: ShipmentService,
    private _authService: AuthService
  ) {}

  ngOnInit(): void {}

  submit() {
    const shipmentId = this.shipmentForm.get("id").value;

    // previously they were disable, if they are left that way the value does not get send
    const containers = <FormArray>this.shipmentForm.get("containers");
    containers.enable();

    this._shipmentService
      .createShipment(<ShipmentCreateDto>{
        id: shipmentId,
        name: this.shipmentForm.get("name").value,
        unitIds: this.shipmentForm.get("containers").value,
        authUsername: this._authService.getUsername(),
      })
      .subscribe(() => {
        this.router.navigate([`/shipment/${shipmentId}`]);
      });
  }

  addUnit() {
    const containers = <FormArray>this.shipmentForm.get("containers");
    const containerLastIndex = containers.length - 1;

    if (
      containerLastIndex >= 0 &&
      containers.controls[containerLastIndex].value.toString() != ""
    ) {
      // if last slot is empty don't add another
      containers.controls[containerLastIndex].disable();
      (<FormArray>this.shipmentForm.get("containers")).push(
        new FormControl(crypto.randomUUID(), [Validators.required])
      );
    } else if (containerLastIndex == -1) {
      // if there is unit added, add unit
      (<FormArray>this.shipmentForm.get("containers")).push(
        new FormControl(crypto.randomUUID(), [Validators.required])
      );
    }
  }

  removeContainer(index: number) {
    (<FormArray>this.shipmentForm.get("containers")).removeAt(index);
    const containers = <FormArray>this.shipmentForm.get("containers");
    const containerLastIndex = containers.length - 1;
    containers.controls[containerLastIndex].enable();
  }

  get containers(): FormArray {
    return this.shipmentForm.get("containers") as FormArray;
  }

  createShipment() {
    this.shipmentForm.get("id").disable();
    this.shipmentForm.get("name").disable();
    (<FormArray>this.shipmentForm.get("containers")).push(
      new FormControl(crypto.randomUUID(), [Validators.required])
    );
    this.createdShipment = true;
  }
}
