import * as QRCode from "qrcode";

import { Component, OnInit } from "@angular/core";
import { UnitDataset, UnitEventDto } from "../model/dto/unit-event.dto";
import { UnitDto } from "../model/dto/unit.dto";
import * as L from "leaflet";
import { DialogMapComponent } from "../dialog-unit-map/dialog-map";
import { ShipmentDto } from "../model/dto/shipment.dto";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { ShipmentService } from "../services/shipment.service";
import { UnitService } from "../services/unit.service";
import { DialogUnitQrCodePrintComponent } from "./dialog-unit-qr/dialog-unit-qr";
import { environment } from "../../environments/environment";
import { UserDetailOverlayComponent } from "../user-detail-overlay/user-detail-overlay.component";

const iconUrl = "assets/marker-icon.png";
const shadowUrl = "assets/marker-shadow.png";

@Component({
  selector: "app-track-shipment",
  templateUrl: "./track-shipment.component.html",
  styleUrls: ["./track-shipment.component.css"],
})
export class TrackShipmentComponent implements OnInit {
  baseUrl = environment.qrCodeApiUrl;

  id: string;
  shipment: ShipmentDto;
  unitEvents: UnitEventDto[];
  lastUnitEvent: UnitEventDto;
  displayedColumns: string[] = [
    "timeCreated",
    "latitude",
    "longitude",
    "meshId",
    "userId",
    "action",
    "message",
    "validity",
  ];

  private map;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private _shipmentService: ShipmentService,
    private _unitService: UnitService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.id = params.get("id");
      this._shipmentService.getShipment(this.id).subscribe((it) => {
        this.shipment = it;
        this._unitService
          .getFirstNEventsForUnits(
            it.units.map((it) => it.id),
            5
          )
          .subscribe((it) => {
            this.unitEvents = it;
            this.getLastEventByShipment();
            this.initMap();
          });
      });
    });
  }

  private initMap(): void {
    L.Marker.prototype.options.icon = L.icon({
      iconUrl: iconUrl,
      shadowUrl: shadowUrl,
      iconAnchor: [12, 40],
    });

    const lastLocation = this.unitEvents[0].location;
    this.map = L.map("map", {
      center: [lastLocation.latitude, lastLocation.longitude],
      zoom: 6,
    });

    this.unitEvents.forEach((it, index) => {
      if (index == 0) {
        const marker = L.marker([
          it.location.latitude,
          it.location.longitude,
        ]).addTo(this.map);
        marker
          .bindPopup("Last Known Location", { offset: L.point(0, -30) })
          .openPopup();
      } else {
        L.marker([it.location.latitude, it.location.longitude]).addTo(this.map);
      }
    });

    const tiles = L.tileLayer(
      "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      {
        maxZoom: 18,
        minZoom: 3,
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }
    );

    tiles.addTo(this.map);
  }

  getEventsByUnitId = (unitId: string): UnitEventDto[] =>
    this.unitEvents.filter((it) => it.unit == unitId);

  getLastEventByShipment() {
    this.lastUnitEvent = this.unitEvents[this.unitEvents.length - 1];
  }

  castDatasetToUnitDataset(value: any): UnitDataset | null {
    if (!value) return null;
    else return value as UnitDataset;
  }

  navigateToUnit(unit: UnitDto) {
    const shipmentName = this.shipment.name;
    const shipmentId = this.shipment.id;
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigate([
        "/unit-event",
        {
          unit: JSON.stringify(unit),
          shipmentId: shipmentId,
          shipmentName: shipmentName,
        },
      ]);
    });
  }

  enlargeMap(): void {
    this.dialog.open(DialogMapComponent, {
      data: {
        locations: this.unitEvents.map((it) => it.location),
        shipmentName: this.shipment.name,
      },
    });
  }

  navigateToCreateUnit() {
    this.router.navigate([`/unit-create/${this.id}`]);
  }

  showCode(id: string) {
    this.dialog.open(DialogUnitQrCodePrintComponent, {
      data: id,
    });
  }

  async printEveryUnitInShipment() {
    const unitIds = this.shipment.units.map((unit) => unit.id);

    const printWindow = window.open("", "_blank");
    if (printWindow) {
      const printContent = await this.generatePrintContent(unitIds);
      printWindow.document.write(printContent);
      printWindow.document.close();
      printWindow.print();
      printWindow.onafterprint = () => printWindow.close();
    } else {
      console.error("Failed to open print window");
    }
  }

  private async generatePrintContent(qrCodeData: string[]): Promise<string> {
    const qrCodes = await Promise.all(
      qrCodeData.map(
        async (data) =>
          `<h1>Unit ID: ${data}</h1> 
            <img style="width: 100%" src="${await this.generateQRCode(
              data
            )}" alt="qrCode"/>`
      )
    );
    return `
  <div>
    ${qrCodes
      .map(
        (qrCode, _) => `
      <div style="break-after: page;">
        ${qrCode}
      </div>
    `
      )
      .join("")}
  </div>
`;
  }
  private async generateQRCode(data: string): Promise<string> {
    try {
      const opts = {
        errorCorrectionLevel: "H",
        type: "image/jpeg",
        quality: 0.3,
        margin: 1,
      };
      return QRCode.toDataURL(this.baseUrl + "/" + data, opts);
    } catch (error) {
      console.error("Error generating QR code:", error);
      throw error;
    }
  }

  openUserDetailOverlay(userId: string, unitId: string) {
    if (userId) {
      this.dialog.open(UserDetailOverlayComponent, {
        data: {
          unitId: unitId,
          userId: userId,
        },
      });
    }
  }
}
