import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ShipmentDto} from "../model/dto/shipment.dto";
import {ShipmentCreateDto} from "../model/dto/shipment-create.dto";
import {environment} from "../../environments/environment";
import {HomeShipmentResponse} from "../model/response/home-shipment.response";

@Injectable({
    providedIn: "root",
})
export class ShipmentService {
    baseUrl = environment.apiUrl;

    constructor(private _httpClient: HttpClient) {
    }

    getShipment = (id: string): Observable<ShipmentDto> =>
        this._httpClient.get<ShipmentDto>(`${this.baseUrl}/shipment/${id}`);

    createShipment = (shipment: ShipmentCreateDto) =>
        this._httpClient.post<ShipmentDto>(`${this.baseUrl}/shipment`, shipment);

    getAllShipmentUpdatedInTheLastNDays = (
        n: number
    ): Observable<HomeShipmentResponse> =>
        this._httpClient.get<HomeShipmentResponse>(
            `${this.baseUrl}/shipment/event/recent/${n}`
        );

    getAllShipmentByUsername = (username: string): Observable<ShipmentDto[]> =>
        this._httpClient.get<ShipmentDto[]>(
            `${this.baseUrl}/shipment/username/${username}`
        );

    getAllShipments = (): Observable<ShipmentDto[]> =>
        this._httpClient.get<ShipmentDto[]>(
            `${this.baseUrl}/shipment`
        )
}
