import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { environment } from "../../../environments/environment";

@Component({
  selector: "dialog-unit-qr-code-print",
  templateUrl: "dialog-unit-qr-code-print.html",
})
export class DialogUnitQrCodePrintComponent {
  baseUrl = environment.qrCodeApiUrl;
  qrCodeData = this.baseUrl + "/" + this.data;

  constructor(@Inject(MAT_DIALOG_DATA) public data: string) {}
}
