<div class="content">
  <h1 *ngIf="shipment">{{shipment.name}}</h1>
  <div style="height: 450px">
    <div style="float: left; width: 45%">
      <div class="row">
        <p class="grow">Shipment ID:</p>
        <p class="fill">{{shipment?.id ?? 'N/A' }}</p>
      </div>
      <div class="row">
        <p class="grow">Shipment status:</p>
        <p class="fill">{{lastUnitEvent?.action ?? 'N/A'}}</p>
      </div>
      <div class="row">
        <p class="grow">Time: </p>
        <p class="fill">{{lastUnitEvent?.timestamp ?? 'N/A'}}</p>
      </div>
      <div class="row">
        <p class="grow">Location: </p>
        <p class="fill">[latitude: {{lastUnitEvent?.location?.latitude ?? 'N/A'}},
          longitude: {{lastUnitEvent?.location?.longitude ?? 'N/A'}}]</p>
      </div>
      <div class="row">
        <p class="grow">Mesh Node: </p>
        <p class="fill">{{lastUnitEvent?.meshId ?? 'N/A'}}</p>
      </div>
      <div class="row">
        <p class="grow">User ID: </p>
        <p class="fill">{{lastUnitEvent?.dataset?.userId ?? 'N/A'}}</p>
      </div>
      <div class="row">
        <p class="grow">Message: </p>
        <p class="fill">{{castDatasetToUnitDataset(lastUnitEvent?.dataset?.value)?.message ?? 'N/A'}}</p>
      </div>
    </div>

    <div class="map-container" style="float: right; width: 45%">
      <div class="map-frame">
        <div id="map"></div>
      </div>
      <p id="explore-map" (click)="enlargeMap()" style="float: right"><u>Explore on larger map</u></p>
    </div>
  </div>


  <div class="myContainer">
    <h3 style="margin: 0">Shipment Units</h3>
    <div style="width: 2em"></div>
    <button class="btn" (click)="navigateToCreateUnit()">
        <div style="display: flex; align-items: center">
            <img src="assets/images/plus-sign-dark.svg" width="30" height="30" alt="Add Unit" />
            <span>ADD UNIT</span>
        </div>
    </button>
    <button class="btn" (click)="printEveryUnitInShipment()">
        <div style="display: flex; align-items: center">
            <img src="assets/images/printer.png" alt="printer" />
            <span>Print every unit in shipment</span>
        </div>
    </button>
  </div>

  <div *ngFor="let unit of shipment.units">
    <div style="height: 50px"></div>
    <div style="display: flex; align-items: center">
    <h5 style="margin: 0">{{unit.id}}</h5>
      <span style="width: 2em"></span>
    <button class="btn" (click)="showCode(unit.id)">
      <div style="display: flex; align-items: center">
        <img src="assets/images/printer.png" alt="Print Code" />
        <span style="margin: 0">SHOW CODE</span>
      </div>
    </button>
    </div>
    <br>
    <table mat-table [dataSource]="getEventsByUnitId(unit.id)" class="mat-elevation-z8">

      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

      <ng-container matColumnDef="timeCreated">
        <th mat-header-cell *matHeaderCellDef>Time Created</th>
        <td mat-cell *matCellDef="let element"> {{element.timestamp}} </td>
      </ng-container>

      <ng-container matColumnDef="latitude">
        <th mat-header-cell *matHeaderCellDef>Latitude</th>
        <td mat-cell *matCellDef="let element"> {{element.location.latitude}} </td>
      </ng-container>

      <ng-container matColumnDef="longitude">
        <th mat-header-cell *matHeaderCellDef>Longitude</th>
        <td mat-cell *matCellDef="let element"> {{element.location.longitude}} </td>
      </ng-container>

      <ng-container matColumnDef="meshId">
        <th mat-header-cell *matHeaderCellDef>Mesh Node</th>
        <td mat-cell *matCellDef="let element"> {{element?.meshId ?? 'N/A'}} </td>
      </ng-container>

      <ng-container matColumnDef="userId">
        <th mat-header-cell *matHeaderCellDef>Username/ID</th>
        <td mat-cell *matCellDef="let element" (click)="openUserDetailOverlay(element.dataset.userId, unit.id)"> {{element?.dataset?.userId ?? "N/A"}} </td>
      </ng-container>


      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let element"> {{element?.action ?? 'N/A'}} </td>
      </ng-container>

      <ng-container matColumnDef="message">
        <th mat-header-cell *matHeaderCellDef>Message</th>
        <td mat-cell *matCellDef="let element"> {{element.dataset?.value?.message ?? 'N/A'}} </td>
      </ng-container>

      <ng-container matColumnDef="validity">
        <th mat-header-cell *matHeaderCellDef>Validity</th>
        <td mat-cell *matCellDef="let element">
          <img *ngIf="element.signature" class="center" src="assets/images/green-checkmark.svg" alt="green checkmark">
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div style="text-align: right; margin-bottom: 50px; padding-top: 15px">
      <button mat-raised-button (click)="navigateToUnit(unit)">Show full unit's
        history
      </button>
    </div>


  </div>

</div>