import { Component } from "@angular/core";
import { AuthService } from "../../services/auth/auth.service";
import { Router } from "@angular/router";
import { LoginDto } from "../../model/dto/login.dto";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DialogInfo } from "../../shared/dialogs/dialog-info/dialog-info";

@Component({
  selector: "dialog-login-print",
  templateUrl: "dialog-login.html",
  styleUrls: ["dialog-login.css"],
})
export class DialogLoginComponent {
  loginForm = new FormGroup({
    username: new FormControl("", [Validators.required]),
    password: new FormControl("", [Validators.required]),
  });

  constructor(
    public dialogRef: MatDialogRef<DialogLoginComponent>,
    private _authService: AuthService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  submit = () => {
    this._authService.login(this.loginForm.value as LoginDto).subscribe(
      () => {
        this.router.navigate(["/home"]);
        this.dialogRef.close();
      },
      (error) => {
        let dialogInfoRef = this.dialog.open(DialogInfo);
        dialogInfoRef.componentInstance.title = "Error";
        console.log(error);
        dialogInfoRef.componentInstance.desc =
          error.error.message ?? "Bad credentials.";
      }
    );
  };
}
